import React, { useEffect, useState } from "react";
import { apiBaseUrl, articlesData, styles } from "../../constants";
import axios from "axios";
import {
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Typography,
  IconButton,
  Paper,
  useMediaQuery,
  Divider,
  List,
  Button,
  CircularProgress,
  Box,
} from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { setAddArticleOpen } from "../../slice/addArticleSlice";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { FlashOn } from "@mui/icons-material";

export default function Articles() {
  const userid = localStorage.getItem("userid");
  const isMobile = useMediaQuery("(max-width:600px)");
  const [articles, setArticles] = useState([]);
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  const getData = () => {
    setLoading(true);
    axios
      .get(`${apiBaseUrl}articles`)
      .then((res) => {
        const processedArticles = res.data.map((article) => ({
          ...article,
          content: JSON.parse(article.content),
          resources: JSON.parse(article.resources),
        }));
        setArticles(processedArticles);
        setLoading(false);
      })
      .catch((err) => {
        setLoading(false);
        console.log(err);
      });
  };
  useEffect(() => {
    getData();
  }, []);
  const dispatch = useDispatch();
  const handleAddArticles = () => {
    navigate("/addArticles");
  };
  return (
    <>
      {loading ? (
        <Box
          sx={{
            position: "fixed",
            top: "50%",
            left: "50%",
            transform: "translate(-50%,-50%)",
            zIndex: 9999,
          }}
        >
          <CircularProgress sx={{ color: "black" }} />
        </Box>
      ) : (
        <>
          <div style={{ margin: "20px" }}>
            {articles.map((item, index) => (
              <Accordion
                key={index}
                style={{ marginBottom: "15px", boxShadow: "none" }}
              >
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls={`panel${index}-content`}
                  id={`panel${index}-header`}
                  style={{
                    backgroundColor: "#f5f5f5",
                    border: "1px solid #ddd",
                    borderRadius: "4px",
                  }}
                >
                  <Typography variant="h5" style={{ fontWeight: "bold" }}>
                    {item.title}
                  </Typography>
                  {index === articles.length - 1 && (
                    <Box
                      sx={{
                        display: "flex",
                        alignItems: "center",
                        backgroundColor: "black",
                        borderRadius: "4px",
                        padding: "0 8px",
                        color: "white",
                        marginLeft: "16px",
                      }}
                    >
                      {" "}
                      <FlashOn sx={{ marginRight: "4px" }} /> Latest{" "}
                    </Box>
                  )}
                </AccordionSummary>

                <AccordionDetails>
                  <Typography
                    variant={isMobile ? "body2" : "subtitle1"} // Smaller subtitle on mobile
                    color="textSecondary"
                  >
                    By {item.author} | {item.date}
                  </Typography>

                  {/* Dynamically Render Headings and Content */}
                  {Object.entries(item.content).map(([key, value], idx) => {
                    // Check if key is a heading or a content
                    if (key.toLowerCase().startsWith("heading")) {
                      return (
                        <Typography
                          key={`heading-${idx}`}
                          variant={isMobile ? "h6" : "h5"}
                          sx={styles.sectionTitle}
                        >
                          {value}
                        </Typography>
                      );
                    } else if (key.toLowerCase().startsWith("content")) {
                      // Render paragraph(s) for content
                      if (Array.isArray(value)) {
                        return value.map((paragraph, pIdx) => (
                          <Typography
                            key={`content-${idx}-${pIdx}`}
                            variant="body1"
                            sx={styles.sectionText}
                          >
                            {paragraph}
                          </Typography>
                        ));
                      } else {
                        return (
                          <Typography
                            key={`content-${idx}`}
                            variant="body1"
                            sx={styles.sectionText}
                          >
                            {value}
                          </Typography>
                        );
                      }
                    }
                    return null; // Skip unhandled keys
                  })}

                  {/* Display Resources */}
                  <Typography
                    variant={isMobile ? "h6" : "h5"}
                    sx={styles.sectionTitle}
                  >
                    Resources
                  </Typography>
                  <List>
                    {item.resources.map((resource, idx) => (
                      <Typography
                        key={`resource-${idx}`}
                        variant="body1"
                        sx={styles.sectionText}
                      >
                        {resource}
                      </Typography>
                    ))}
                  </List>
                </AccordionDetails>
              </Accordion>
            ))}
          </div>
          {Number(userid) === 17 && (
            <Button
              style={{
                margin: "20px",
                backgroundColor: "black",
                color: "white",
              }}
              onClick={handleAddArticles}
            >
              Add Articles
            </Button>
          )}
        </>
      )}
    </>
  );
}
