import { Box, Container, Grid, Typography, IconButton } from "@mui/material";
import EmailIcon from "@mui/icons-material/Email";
import InstagramIcon from "@mui/icons-material/Instagram";
import YouTubeIcon from "@mui/icons-material/YouTube";
import logo from "../logo.png";
export default function Footer() {
  return (
    <Box
      sx={{
        backgroundColor: "black",
        color: "white",
        padding: "40px 0",
      }}
    >
      <Container>
        <Grid container spacing={4}>
          <Grid item xs={12} md={6}>
            <Typography variant="h4" gutterBottom>
              <img
                src={logo}
                alt="Brain4Brains"
                style={{ width: "50px", marginBottom: "10px" }}
              />
            </Typography>
            <Typography variant="body1" gutterBottom>
              Advancing the future of neuroscience, one discovery at a time
            </Typography>

            <Box>
              <IconButton color="inherit" href="mailto:info@example.com">
                <EmailIcon />
              </IconButton>
              <IconButton color="inherit" href="https://instagram.com">
                <InstagramIcon />
              </IconButton>
              <IconButton color="inherit" href="https://youtube.com">
                <YouTubeIcon />
              </IconButton>
            </Box>
          </Grid>

          {/* Right side: Links */}
          <Grid item xs={12} md={6}>
            <Typography variant="h6" gutterBottom>
              We're unlocking the mysteries of the brain—join us in advancing
              neuroscience for a brighter future!
            </Typography>
            <Box>
              <Typography variant="body2">
                <a
                  href="our-story"
                  style={{ color: "white", textDecoration: "none" }}
                >
                  Our Story
                </a>
              </Typography>
              <Typography variant="body2">
                <a
                  href="leadership-panel"
                  style={{ color: "white", textDecoration: "none" }}
                >
                  Team Members
                </a>
              </Typography>
              <Typography variant="body2">
                <a
                  href="blog"
                  style={{ color: "white", textDecoration: "none" }}
                >
                  Blog posts
                </a>
              </Typography>
              <Typography variant="body2">
                <a
                  href="articles"
                  style={{ color: "white", textDecoration: "none" }}
                >
                  Articles
                </a>
              </Typography>

              <Typography variant="body2">
                <a
                  href="contact"
                  style={{ color: "white", textDecoration: "none" }}
                >
                  Help
                </a>
              </Typography>
            </Box>
          </Grid>
        </Grid>

        {/* Copyright Section */}
        <Box textAlign="center" mt={4}>
          <Typography variant="body2">
            © 2024 Brain4Brains. All rights reserved. Brain4Brains is a
            qualified 501(c)(3) non-profit organization.
          </Typography>
        </Box>
      </Container>
    </Box>
  );
}
