import React, { useState, useEffect } from "react";
import {
  IconButton,
  Badge,
  MenuItem,
  ListItem,
  ListItemText,
  Box,
  Popper,
  Paper,
  Typography,
  Button,
  Grid,
} from "@mui/material";
import NotificationsIcon from "@mui/icons-material/Notifications";
import ArrowDropUpIcon from "@mui/icons-material/ArrowDropUp";
import { toast } from "material-react-toastify";
import { apiBaseUrl } from "../constants";
import axios from "axios";
import { CloseOutlined } from "@mui/icons-material";

export default function NotificationDropdown() {
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);

  const handleOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const [notifications, setNotifications] = useState([]);
  const getNotifications = async (event) => {
    try {
      const response = await axios.get(`${apiBaseUrl}notifications`);

      if (response.status === 200) {
        console.log(response.data);
        setNotifications(response.data);
      } else {
        toast.error("An error occurred. Please try again.");
      }
    } catch (err) {
      if (err.response) {
        toast.error(err.response.message) ||
          toast.error("Internal server error");
      }
    }
  };
  useEffect(() => {
    getNotifications();
  }, []);

  return (
    <div>
      <IconButton
        color="inherit"
        onClick={handleOpen}
        sx={{ color: "#333", marginLeft: "20px" }}
      >
        <Badge
          badgeContent={notifications.length}
          sx={{
            "& .MuiBadge-badge": {
              backgroundColor: "#333",
              color: "#fff",
            },
          }}
        >
          <NotificationsIcon fontSize="30px" />
        </Badge>
      </IconButton>

      <Popper
        open={open}
        anchorEl={anchorEl}
        placement="bottom-end"
        style={{ zIndex: 1300 }}
      >
        <Box display="flex" justifyContent="flex-end">
          <ArrowDropUpIcon
            style={{ color: "#333", marginRight: 10, fontSize: "30px" }}
          />
        </Box>

        <Paper sx={{ width: 320, padding: "10px", boxShadow: 3 }}>
          <Grid container>
            <Grid xs={6}>
              <Typography
                variant="h6"
                sx={{ padding: "8px", fontWeight: "bold", color: "#333" }}
              >
                Notifications
              </Typography>
            </Grid>
            <Grid xs={6}>
              <Button
                onClick={handleClose}
                sx={{ float: "right", color: "#333" }}
              >
                <CloseOutlined />
              </Button>
            </Grid>
          </Grid>

          {notifications.length > 0 ? (
            notifications.map((notification, index) => (
              <MenuItem
                key={index}
                onClick={handleClose}
                sx={{ padding: "10px" }}
              >
                <ListItem disableGutters>
                  <ListItemText
                    primary={
                      <Typography
                        style={{ textDecoration: "none", color: "#333" }}
                      >
                        {notification.name}
                      </Typography>
                    }
                  />
                </ListItem>
              </MenuItem>
            ))
          ) : (
            <MenuItem onClick={handleClose}>No new notifications</MenuItem>
          )}
        </Paper>
      </Popper>
    </div>
  );
}
