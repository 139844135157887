import React, { useRef } from "react";
import { Box, Grid, Typography, TextField, Button } from "@mui/material";
import emailjs from "@emailjs/browser";
import { ToastContainer, toast } from "material-react-toastify";
import "material-react-toastify/dist/ReactToastify.css";
import { Phone } from "@mui/icons-material";
import { Mail } from "@mui/icons-material";
import "../Animation.css";
export default function Contact() {
  const form = useRef();

  const sendEmail = (e) => {
    e.preventDefault();

    emailjs
      .sendForm("service_q2hl84u", "template_rqwtlde", form.current, {
        publicKey: "-Z2knMZ9dS_OBYIkv",
      })
      .then(
        () => {
          console.log("SUCCESS!");
          toast.success("Thanks for contacting us !");
          setTimeout(() => {
            window.location.reload();
          }, 4000);
        },
        (error) => {
          console.log("FAILED...", error.text);
          toast.error("Please try again later !");
        }
      );
  };
  return (
    <>
      <ToastContainer
        position="top-right" // Set the position to top-left
        autoClose={3000} // Automatically close after 5 seconds
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />

      <div className="container">
        <div className="bubbles">
          <span style={{ "--i": 11 }}></span>
          <span style={{ "--i": 12 }}></span>
          <span style={{ "--i": 24 }}></span>
          <span style={{ "--i": 10 }}></span>
          <span style={{ "--i": 14 }}></span>
          <span style={{ "--i": 23 }}></span>
          <span style={{ "--i": 18 }}></span>
          <span style={{ "--i": 16 }}></span>
          <span style={{ "--i": 19 }}></span>
          <span style={{ "--i": 20 }}></span>
          <span style={{ "--i": 22 }}></span>
          <span style={{ "--i": 25 }}></span>
          <span style={{ "--i": 18 }}></span>
          <span style={{ "--i": 21 }}></span>
          <span style={{ "--i": 15 }}></span>
          <span style={{ "--i": 13 }}></span>
          <span style={{ "--i": 26 }}></span>
          <span style={{ "--i": 17 }}></span>
          <span style={{ "--i": 13 }}></span>
          <span style={{ "--i": 28 }}></span>
          <span style={{ "--i": 11 }}></span>
          <span style={{ "--i": 12 }}></span>
          <span style={{ "--i": 24 }}></span>
          <span style={{ "--i": 10 }}></span>
          <span style={{ "--i": 14 }}></span>
          <span style={{ "--i": 23 }}></span>
          <span style={{ "--i": 18 }}></span>
          <span style={{ "--i": 16 }}></span>
          <span style={{ "--i": 19 }}></span>
          <span style={{ "--i": 20 }}></span>
          <span style={{ "--i": 22 }}></span>
          <span style={{ "--i": 25 }}></span>
          <span style={{ "--i": 18 }}></span>
          <span style={{ "--i": 21 }}></span>
          <span style={{ "--i": 15 }}></span>
          <span style={{ "--i": 13 }}></span>
          <span style={{ "--i": 26 }}></span>
          <span style={{ "--i": 17 }}></span>
          <span style={{ "--i": 13 }}></span>
          <span style={{ "--i": 28 }}></span>
        </div>
        <Box
          sx={{
            minHeight: "80vh",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            padding: "40px",
          }}
        >
          <Box
            sx={{
              backgroundColor: "rgba(255, 255, 255, 0.95)",
              borderRadius: "16px",
              padding: "30px",
              boxShadow: "0 8px 24px rgba(0, 0, 0, 0.2)",
              width: "100%",
              maxWidth: "900px",
              zIndex: 2,
            }}
          >
            <Grid container spacing={4}>
              <Grid item xs={12} md={6}>
                <Typography
                  variant="h5"
                  fontFamily="Urbanist"
                  color="#333"
                  fontWeight="bold"
                >
                  General Inquiries
                </Typography>
                <Typography
                  variant="body1"
                  fontFamily="Urbanist"
                  color="#333"
                  mt={2}
                >
                  If you have any general questions, feel free to reach out:
                </Typography>
                <Box display="flex" alignItems="center" mt={2}>
                  <Phone sx={{ marginRight: "8px", color: "#333" }} />
                  <Typography
                    variant="body1"
                    fontFamily="Urbanist"
                    color="#333"
                  >
                    +1-513-693-9610
                  </Typography>
                </Box>
                <Box display="flex" alignItems="center" mt={1}>
                  <Mail sx={{ marginRight: "8px", color: "#333" }} />
                  <Typography
                    variant="body1"
                    fontFamily="Urbanist"
                    color="#333"
                  >
                    info@brain4brains.org
                  </Typography>
                </Box>
              </Grid>
              <Grid item xs={12} md={6}>
                <Typography
                  variant="h5"
                  fontFamily="Urbanist"
                  color="#333"
                  fontWeight="bold"
                >
                  Specific Questions
                </Typography>
                <form
                  ref={form}
                  onSubmit={sendEmail}
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    gap: "20px",
                    marginTop: "16px",
                  }}
                >
                  <TextField
                    label="Name"
                    name="user_name"
                    required
                    variant="outlined"
                    fullWidth
                    InputLabelProps={{ style: { color: "#333" } }}
                    sx={{
                      "& .MuiOutlinedInput-root": {
                        "& fieldset": { borderColor: "#333" },
                        "&:hover fieldset": { borderColor: "#1A73E8" },
                      },
                    }}
                  />
                  <TextField
                    label="Email"
                    name="user_email"
                    type="email"
                    required
                    variant="outlined"
                    fullWidth
                    InputLabelProps={{ style: { color: "#333" } }}
                    sx={{
                      "& .MuiOutlinedInput-root": {
                        "& fieldset": { borderColor: "#333" },
                        "&:hover fieldset": { borderColor: "#1A73E8" },
                      },
                    }}
                  />
                  <TextField
                    label="Message"
                    name="message"
                    required
                    variant="outlined"
                    multiline
                    rows={4}
                    fullWidth
                    InputLabelProps={{ style: { color: "#333" } }}
                    sx={{
                      "& .MuiOutlinedInput-root": {
                        "& fieldset": { borderColor: "#333" },
                        "&:hover fieldset": { borderColor: "#1A73E8" },
                      },
                    }}
                  />
                  <Button
                    type="submit"
                    variant="contained"
                    sx={{
                      backgroundColor: "#333",
                      color: "#fff",
                      borderRadius: "20px",
                      padding: "10px 20px",
                      fontWeight: "bold",
                      "&:hover": {
                        backgroundColor: "#1A73E8",
                      },
                    }}
                  >
                    Send
                  </Button>
                </form>
              </Grid>
            </Grid>
          </Box>
        </Box>
      </div>
    </>
  );
}
