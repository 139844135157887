import React, { useState } from "react";
import {
  IconButton,
  TextField,
  Box,
  Dialog,
  DialogContent,
  Button,
} from "@mui/material";
import ChatIcon from "@mui/icons-material/Chat";
import { predefinedAnswers, predefinedQuestions } from "../../constants";
import VerifiedUser from "@mui/icons-material/VerifiedUser";
import { CloseOutlined } from "@mui/icons-material";
export default function Chatbot() {
  const [open, setOpen] = useState(false);
  const username = localStorage.getItem("username");
  const [messages, setMessages] = useState([
    { sender: "bot", text: `Hi, I am BrainBot! How can I help you today?` },
  ]);
  const [input, setInput] = useState("");

  const toggleChat = () => {
    setOpen(!open);
  };

  const handleSend = (text) => {
    const newMessages = [...messages, { sender: "user", text }];
    setMessages(newMessages);

    if (predefinedAnswers[text]) {
      setMessages([
        ...newMessages,
        { sender: "bot", text: predefinedAnswers[text] },
      ]);
    } else if (["thanks", "bye", "welcome"].includes(text.toLowerCase())) {
      setMessages([...newMessages, { sender: "bot", text: "Thank you." }]);
    } else {
      setMessages([
        ...newMessages,
        {
          sender: "bot",
          text:
            "If you have any questions, please feel free to reach out to us at info@brain4brains.org.",
        },
      ]);
    }
    setInput("");
  };

  return (
    <Box>
      <IconButton
        color="primary"
        onClick={toggleChat}
        sx={{ position: "fixed", bottom: 16, right: 16, zIndex: 2000 }}
      >
        <ChatIcon
          sx={{
            backgroundColor: "transparent",
            color: "grey",
            fontSize: "50px",
            borderRadius: "10px",
          }}
        />
      </IconButton>
      <Dialog open={open}>
        <DialogContent>
          <Box
            sx={{ minHeight: "300px", maxHeight: "300px", overflowY: "auto" }}
          >
            <Button
              onClick={toggleChat}
              sx={{
                float: "right",
                color: "#333",
                fontSize: "20px",
              }}
            >
              <CloseOutlined />
            </Button>
            {messages.map((message, index) => (
              <Box
                key={index}
                sx={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent:
                    message.sender === "user" ? "flex-end" : "flex-start",
                  padding: "10px",
                }}
              >
                <IconButton color="primary" sx={{ marginRight: "5px" }}>
                  <VerifiedUser />
                </IconButton>
                <div
                  style={{
                    border: "1px solid #333",
                    borderRadius: "8px",
                    padding: "10px",
                    backgroundColor: "#f5f5f5",
                    maxWidth: "300px",
                    margin: "10px 0",
                  }}
                >
                  <p
                    style={{
                      color: "#333",
                      fontFamily: "Urbanist",
                      margin: 0,
                    }}
                  >
                    {message.text}
                  </p>
                </div>
              </Box>
            ))}
            {predefinedQuestions.map((question, index) => (
              <Button
                key={index}
                variant="outlined"
                sx={{
                  marginTop: "10px",
                  borderRadius: "40px",
                  backgroundColor: "#fff",
                  color: "#333",
                  border: "1px solid #333",
                }}
                onClick={() => handleSend(question)}
              >
                {question}
              </Button>
            ))}
          </Box>
          <TextField
            fullWidth
            variant="outlined"
            placeholder="Type your message..."
            value={input}
            onChange={(e) => setInput(e.target.value)}
            onKeyPress={(e) => {
              if (e.key === "Enter") handleSend(input);
            }}
          />
        </DialogContent>
      </Dialog>
    </Box>
  );
}
