import React, { useState, useEffect } from "react";
import { Box, Typography, TextField, Button } from "@mui/material";
import { ToastContainer, toast } from "material-react-toastify";
import "material-react-toastify/dist/ReactToastify.css";
import axios from "axios";
import { apiBaseUrl } from "../../constants";
import newsletter from "../../assets/newsletter.jpeg";
export default function Newsletter() {
  const [email, setEmail] = useState("");
  const scriptURL =
    "https://script.google.com/macros/s/AKfycbx83e6GOtlTAwgbCSK9xXHZRKfEFKNWLmZ66PwmRBHgiuRZLQ6I4e-VS2k8W9Ew49Tw/exec";

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (email.trim() === "") {
      toast.error("No value is typed");
      return;
    }
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    if (!emailRegex.test(email)) {
      // Validate the email format
      toast.error("Please enter a valid email address");
      return;
    }
    console.log(email);
    const data = { email: email };
    try {
      const response = await axios.post(`${apiBaseUrl}signup-newsletter`, data);
      console.log(response.data);
      if (response.data.message === "User registered successfully") {
        toast.success("Registered successfully");
        setEmail("");
      } else {
        toast.error("Please try again later!!");
      }
    } catch (error) {
      console.log("Error", error);
      if (error.response) {
        toast.error(error.response.data.message) ||
          toast.error("Internal server error");
      }
    }
  };

  return (
    <>
      <ToastContainer
        position="top-right" // Set the position to top-left
        autoClose={3000} // Automatically close after 5 seconds
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />
      <Box
        sx={{
          height: "67vh",
          display: "flex",
          flexDirection: "column", // Stack elements vertically
          alignItems: "center",
          justifyContent: "center",
          backgroundColor: "#fff",
          position: "relative",
          padding: "20px",
        }}
      >
        <Typography
          variant="h3"
          sx={{
            fontFamily: "Urbanist",
            fontWeight: "bold",
            color: "#333",
            textAlign: "center", // Center the text on all screen sizes
            fontSize: { xs: "1.8rem", md: "2.5rem" }, // Responsive font size
            marginBottom: "20px", // Add margin to space out from the form
          }}
          gutterBottom
        >
          Sign Up for Our Newsletter
        </Typography>
        <Typography
          variant="h4"
          sx={{
            fontFamily: "Urbanist",
            color: "#333",
            textAlign: "center", // Center the text on all screen sizes
            fontSize: { xs: "1.2rem", md: "1.5rem" }, // Responsive font size
            marginBottom: "20px", // Add margin to space out from the form
          }}
          gutterBottom
        >
          To get more innovative updates on neuroscience!!
        </Typography>
        <form
          onSubmit={handleSubmit}
          style={{
            display: "flex",
            flexDirection: "column",
            gap: "30px",
            alignItems: "center",
            width: "100%", // Make the form width 100% to adapt to screen size
          }}
        >
          <TextField
            label="Email"
            InputProps={{
              style: { color: "#333" }, // Make the text color white
            }}
            sx={{
              "& .MuiInputLabel-root": { color: "#333" }, // Label color
              "& .MuiOutlinedInput-root": {
                "& fieldset": { borderColor: "#333" }, // Border color
              },
              width: { xs: "100%", sm: "400px" }, // Responsive width
            }}
            size="medium"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
          />
          <Button
            type="submit"
            sx={{
              backgroundColor: "#333",
              fontFamily: "Urbanist",
              color: "#fff",
              width: { xs: "100%", sm: "300px" }, // Responsive button width
              "&:hover": {
                backgroundColor: "#333", // Keep background color blue on hover
                color: "#fff", // Keep text color the same on hover
              },
              "&:active": {
                backgroundColor: "#333", // Keep background color blue on click
                color: "#fff", // Keep text color the same on click
              },
            }}
          >
            Subscribe
          </Button>
        </form>
      </Box>
    </>
  );
}
