import React from "react";
import { Box } from "@mui/material";
import Counter from "../Statistics/Counter.jsx";

const StatisticsSection = () => {
  return (
    <Box
      sx={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        padding: "50px",
        gap: {
          xs: "20px", // Gap for extra small screens
          sm: "40px", // Gap for small screens
          md: "80px", // Gap for medium and larger screens
        },
        flexDirection: {
          xs: "column", // Stack items vertically on extra small screens
          sm: "column", // Stack items vertically on small screens
          md: "row", // Row layout for medium and larger screens
        },
        backgroundColor: "#FFF",
      }}
    >
      <Counter
        target={100000}
        label="Neurons in a Fruit Fly Brain"
        duration={2000}
      />
      <Counter target={2000} label="Mitochondria per Neuron" duration={2000} />
      <Counter target={300} label="Lives Touched" duration={2000} />
      <Counter
        target={86000000000}
        label="Neurons in the Human Brain"
        duration={2000}
      />
    </Box>
  );
};

export default StatisticsSection;
