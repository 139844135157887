import { combineReducers } from "@reduxjs/toolkit";
import countSlice from "./slice/cartSlice";
import cartSlice from "./slice/cartSlice";
import userEntry from "./slice/userEntry";
import loggedInSlice from "./slice/loggedInSlice";
import addArticleSlice from "./slice/addArticleSlice"

export const rootreducer = combineReducers({
    // countSlice:countSlice,
    cart:cartSlice,
    userEntry:userEntry,
    login: loggedInSlice,
    addArticle: addArticleSlice
})