import React, { useEffect, useState } from "react";
import {
  Typography,
  Button,
  Box,
  Grid,
  Container,
  Card,
  CardContent,
  TextField,
  InputAdornment,
  Modal,
  Paper,
  MenuItem,
  CircularProgress,
  IconButton,
  Tooltip,
  Menu,
} from "@mui/material";
import axios from "axios";
import { apiBaseUrl } from "../../constants";
import { useSelector } from "react-redux";
import { toast } from "material-react-toastify";
import MissionVisionValues from "./MissionVisionValues";
import { Link } from "react-router-dom";
import { CloseOutlined, Person } from "@mui/icons-material";
import { Lock } from "@mui/icons-material";
import { Email } from "@mui/icons-material";
import StatisticsSection from "../LandingPageSection/Statistics/Statics.jsx";
import { useDispatch } from "react-redux";
import { setLoggedIn } from "../../slice/loggedInSlice";
import logo from "../../logo.png";
import FeaturedReportSection from "./Report";
import OverlaySection from "./Banner";
import neuron from "../../assets/backgr.jpg";
export default function Home() {
  const [open, setOpen] = useState(false);
  const [openlogin, setOpenlogin] = useState(false);
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [passwordLogin, setPasswordLogin] = useState("");
  const [emailLogin, setEmailLogin] = useState("");
  const [password, setPassword] = useState("");
  const loggedin = useSelector((state) => state.login.isLoggedIn);
  const [username, setUsername] = useState("");
  const [userid, setUserid] = useState(null);
  const [loading, setLoading] = useState(false);

  const handleOpen = () => {
    setOpen(true);
    setOpenlogin(false);
  };

  const handleClose = () => {
    setOpen(false);
    setEmail("");
    setPassword("");
    setName("");
  };

  const handleLoginClick = () => {
    setOpenlogin(true);
  };
  const handleSignupClick = () => {
    setOpenlogin(false);
  };

  const validateEmail = (email) => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    if (!emailRegex.test(email)) {
      toast.error("Please enter a valid email.");
      return false;
    }
    return true;
  };

  const validatePassword = (password) => {
    if (password.length <= 8) {
      toast.error("Password should be longer than 8 characters.");
      return false;
    }
    return true;
  };

  const handleSubmitLogin = async (event) => {
    event.preventDefault();
    if (emailLogin.trim() === "" || passwordLogin.trim() === "") {
      toast.error("Please fill in all fields.");
      return;
    }
    if (!validateEmail(emailLogin) || !validatePassword(passwordLogin)) return;

    try {
      setLoading(true);
      const response = await axios.post(`${apiBaseUrl}login`, {
        email: emailLogin,
        password: passwordLogin,
      });
      console.log(response.data);

      if (response.data.message === "Login successful") {
        console.log(response.data);
        toast.success("Login successful!");
        setOpen(false);
        setLoading(false);
        const array = response.data.data.map((item) => item.username);
        const uid = response.data.data.map((item) => item.id);
        setUsername(array);
        setUserid(uid);
        dispatch(setLoggedIn(true));
        localStorage.setItem("username", array);
        localStorage.setItem("userid", uid);
      } else {
        toast.error("An error occurred. Please try again.");
        setLoading(false);
      }
    } catch (err) {
      if (err.response) {
        setLoading(false);
        toast.error(err.response.message) ||
          toast.error("Internal server error");
      }
      setLoading(false);
    }
    setEmailLogin("");
    setPasswordLogin("");
  };

  const handleSubmitSignup = async (event) => {
    event.preventDefault();
    if (name.trim() === "" || email.trim() === "" || password.trim() === "") {
      toast.error("Please fill in all fields.");
      return;
    }
    if (!validateEmail(email) || !validatePassword(password)) return;

    try {
      setLoading(true);
      const response = await axios.post(`${apiBaseUrl}signup`, {
        username: name,
        email: email,
        password: password,
      });

      if (response.data.message === "User registered successfully") {
        toast.success("Sign up successful!");
        const array = response.data.data.username;
        const uid = response.data.data.userId;
        console.log(array);
        setUsername(array);
        setUserid(uid);
        dispatch(setLoggedIn(true));
        localStorage.setItem("username", array);
        localStorage.setItem("userid", uid);
        setLoading(false);
      } else {
        toast.error("Sign up failed. Try again.");
        setLoading(false);
      }
    } catch (err) {
      if (err.response) {
        setLoading(false);
        toast.error(err.response.message) ||
          toast.error("Internal server error");
      }
      console.log(err);
      setLoading(false);
    }
    setName("");
    setEmail("");
    setPassword("");
  };

  useEffect(() => {
    const storedUsername = localStorage.getItem("username");
    const storedUserid = localStorage.getItem("userid");
    if (storedUsername) {
      setUserid(storedUserid);
      setUsername(storedUsername);
      dispatch(setLoggedIn(true));
    }
  }, []);
  const dispatch = useDispatch();
  const handleLogout = () => {
    setUsername("");
    dispatch(setLoggedIn(false));
    localStorage.removeItem("username");
    localStorage.removeItem("userid");
  };
  const [anchorElUser, setAnchorElUser] = useState(null);
  const handleOpenUserMenu = (event) => {
    setAnchorElUser(event.currentTarget);
  };
  const handleCloseUserMenu = (event) => {
    setAnchorElUser(null);
  };
  return (
    <>
      <Box
        className="hero"
        sx={{
          display: "flex",
          alignItems: "center",
          color: "white",
          backgroundColor: "black",
          position: "relative",
          overflow: "hidden", // This prevents overflow
          height: "100vh",
          flexDirection: { xs: "column", md: "row" },
          justifyContent: { xs: "center", md: "space-between" },
          padding: { xs: 2, md: 4 },
          textAlign: { xs: "center", md: "left" },
          maxWidth: "100vw", // Prevents extra white space
        }}
      >
        <Container sx={{ position: "relative", zIndex: 2 }}>
          <Typography variant="h3" gutterBottom>
            Welcome To Brain4Brains
          </Typography>
          <Typography variant="h6" paragraph>
            Empowering minds with neuroscience insights
          </Typography>

          {loggedin ? (
            <>
              <Tooltip title={username}>
                <Button
                  variant="contained"
                  size="large"
                  color="warning"
                  sx={{
                    textTransform: "none",
                    padding: "10px 20px",
                    borderRadius: "50px",
                  }}
                  onClick={handleLogout}
                >
                  Logout
                </Button>
              </Tooltip>
            </>
          ) : (
            <>
              <Button
                variant="contained"
                size="large"
                color="warning"
                sx={{
                  textTransform: "none",
                  padding: "10px 20px",
                  borderRadius: "50px",
                }}
                onClick={handleOpen}
              >
                Login / Signup
              </Button>
              <Modal
                open={open}
                aria-labelledby="modal-title"
                aria-describedby="modal-description"
              >
                {loading ? (
                  <CircularProgress />
                ) : (
                  <Paper
                    className="login-modal"
                    elevation={3}
                    sx={{
                      position: "absolute",
                      top: "50%",
                      left: "50%",
                      transform: "translate(-50%, -50%)",
                      justifyContent: "center",
                      alignItems: "center",
                      width: { xs: "60%", md: "40%" }, // Adjust for smaller screens
                      p: 3,
                      backgroundColor: "#FFF",
                      borderRadius: 2,
                      boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.1)",
                    }}
                  >
                    <Box
                      sx={{
                        display: "flex",
                        justifyContent: "space-between", // Use space-between to create space between logo and button
                        alignItems: "center",
                        mb: 2,
                        position: "relative", // Position relative for the button
                      }}
                    >
                      <Box
                        sx={{
                          display: "flex",
                          justifyContent: "center",
                          flexGrow: 1,
                        }}
                      >
                        <img
                          src={logo}
                          alt="logo"
                          style={{
                            width: "60px",
                            justifyContent: "center",
                            alignContent: "center",
                          }}
                        />
                      </Box>

                      <Button
                        onClick={handleClose}
                        sx={{
                          position: "absolute", // Position absolute to move it to the right
                          right: 0, // Align it to the right
                          color: "#fff",
                          backgroundColor: "#333",
                          borderRadius: "50px",
                          "&:hover": {
                            backgroundColor: "#333", // Maintain transparent background on hover
                          },
                        }}
                      >
                        <CloseOutlined />
                      </Button>
                    </Box>

                    {openlogin ? (
                      <Box
                        sx={{
                          display: "flex",
                          flexDirection: "column",
                          alignItems: "flex-start", // Align items to the left
                          justifyContent: "center",
                          color: "#333",
                        }}
                      >
                        <TextField
                          value={emailLogin}
                          onChange={(e) => setEmailLogin(e.target.value)}
                          fullWidth
                          margin="normal"
                          size="small"
                          sx={{
                            input: { color: "#333" },
                            bgcolor: "#F0F0F0",
                            borderRadius: 1,
                          }}
                          placeholder="Enter your email"
                          InputProps={{
                            startAdornment: (
                              <InputAdornment position="start">
                                <Email sx={{ color: "#333" }} />
                              </InputAdornment>
                            ),
                          }}
                        />
                        <TextField
                          value={passwordLogin}
                          onChange={(e) => setPasswordLogin(e.target.value)}
                          type="password"
                          fullWidth
                          margin="normal"
                          size="small"
                          sx={{
                            input: { color: "#333" },
                            bgcolor: "#F0F0F0",
                            borderRadius: 1,
                          }}
                          placeholder="Enter your password"
                          InputProps={{
                            startAdornment: (
                              <InputAdornment position="start">
                                <Lock sx={{ color: "#333" }} />
                              </InputAdornment>
                            ),
                          }}
                        />

                        <Box
                          sx={{
                            display: "flex",
                            justifyContent: "center",
                            width: "100%",
                          }}
                        >
                          <Button
                            type="submit"
                            onClick={handleSubmitLogin}
                            sx={{
                              mt: 2,
                              backgroundColor: "#333",
                              color: "#FFF",
                              "&:hover": {
                                backgroundColor: "#1A73E8",
                              },
                            }}
                          >
                            Submit
                          </Button>
                        </Box>

                        <Link
                          onClick={handleSignupClick}
                          sx={{
                            mt: 2,
                            color: "#333",
                            cursor: "pointer",
                            textAlign: "center",
                            width: "100%",
                          }}
                        >
                          Sign Up
                        </Link>
                      </Box>
                    ) : (
                      <Box
                        sx={{
                          display: "flex",
                          flexDirection: "column",
                          alignItems: "flex-start", // Align items to the left
                          justifyContent: "center",
                          color: "#333",
                        }}
                      >
                        <TextField
                          value={name}
                          onChange={(e) => setName(e.target.value)}
                          fullWidth
                          margin="normal"
                          size="small"
                          sx={{
                            input: { color: "#333" },
                            bgcolor: "#F0F0F0",
                            borderRadius: 1,
                          }}
                          placeholder="Enter your name"
                          InputProps={{
                            startAdornment: (
                              <InputAdornment position="start">
                                <Person sx={{ color: "#333" }} />
                              </InputAdornment>
                            ),
                          }}
                        />

                        <TextField
                          value={email}
                          onChange={(e) => setEmail(e.target.value)}
                          fullWidth
                          margin="normal"
                          size="small"
                          sx={{
                            input: { color: "#333" },
                            bgcolor: "#F0F0F0",
                            borderRadius: 1,
                          }}
                          placeholder="Enter your email"
                          InputProps={{
                            startAdornment: (
                              <InputAdornment position="start">
                                <Email sx={{ color: "#333" }} />
                              </InputAdornment>
                            ),
                          }}
                        />

                        <TextField
                          value={password}
                          onChange={(e) => setPassword(e.target.value)}
                          type="password"
                          fullWidth
                          margin="normal"
                          size="small"
                          sx={{
                            input: { color: "#333" },
                            bgcolor: "#F0F0F0",
                            borderRadius: 1,
                          }}
                          placeholder="Enter your password"
                          InputProps={{
                            startAdornment: (
                              <InputAdornment position="start">
                                <Lock sx={{ color: "#333" }} />
                              </InputAdornment>
                            ),
                          }}
                        />

                        <Box
                          sx={{
                            display: "flex",
                            justifyContent: "center",
                            width: "100%",
                          }}
                        >
                          <Button
                            type="submit"
                            onClick={handleSubmitSignup}
                            sx={{
                              mt: 2,
                              backgroundColor: "#333",
                              color: "#FFF",
                              "&:hover": {
                                backgroundColor: "#1A73E8",
                              },
                            }}
                          >
                            Submit
                          </Button>
                        </Box>

                        <Typography
                          variant="h6"
                          sx={{
                            mt: 2,
                            color: "#333",
                            width: "100%",
                            textAlign: "center",
                          }}
                        >
                          Already a user?
                        </Typography>
                        <Link
                          onClick={handleLoginClick}
                          sx={{
                            mt: 1,
                            color: "#333",
                            cursor: "pointer",
                            textAlign: "center",
                            width: "100%",
                          }}
                        >
                          Login
                        </Link>
                      </Box>
                    )}
                  </Paper>
                )}
              </Modal>{" "}
            </>
          )}
        </Container>
        <Box
          component="img"
          alt="Neuron"
          src={neuron}
          sx={{
            position: "absolute",
            top: 0,
            left: 0,
            width: "100%", // Ensures it fits within the screen
            height: "100%",
            objectFit: "cover",
            opacity: 0.4,
            zIndex: 1,
          }}
        />
      </Box>

      <div id="mvv">
        <MissionVisionValues />
      </div>
      <FeaturedReportSection />
      <StatisticsSection />
      <OverlaySection />
    </>
  );
}
