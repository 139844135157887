import React from "react";
import { Box, Paper, Typography } from "@mui/material";
import "./Animation.css";
function Animation() {
  return (
    <div className="container">
      <div className="bubbles">
        <span style={{ "--i": 11 }}></span>
        <span style={{ "--i": 12 }}></span>
        <span style={{ "--i": 24 }}></span>
        <span style={{ "--i": 10 }}></span>
        <span style={{ "--i": 14 }}></span>
        <span style={{ "--i": 23 }}></span>
        <span style={{ "--i": 18 }}></span>
        <span style={{ "--i": 16 }}></span>
        <span style={{ "--i": 19 }}></span>
        <span style={{ "--i": 20 }}></span>
        <span style={{ "--i": 22 }}></span>
        <span style={{ "--i": 25 }}></span>
        <span style={{ "--i": 18 }}></span>
        <span style={{ "--i": 21 }}></span>
        <span style={{ "--i": 15 }}></span>
        <span style={{ "--i": 13 }}></span>
        <span style={{ "--i": 26 }}></span>
        <span style={{ "--i": 17 }}></span>
        <span style={{ "--i": 13 }}></span>
        <span style={{ "--i": 28 }}></span>
        <span style={{ "--i": 11 }}></span>
        <span style={{ "--i": 12 }}></span>
        <span style={{ "--i": 24 }}></span>
        <span style={{ "--i": 10 }}></span>
        <span style={{ "--i": 14 }}></span>
        <span style={{ "--i": 23 }}></span>
        <span style={{ "--i": 18 }}></span>
        <span style={{ "--i": 16 }}></span>
        <span style={{ "--i": 19 }}></span>
        <span style={{ "--i": 20 }}></span>
        <span style={{ "--i": 22 }}></span>
        <span style={{ "--i": 25 }}></span>
        <span style={{ "--i": 18 }}></span>
        <span style={{ "--i": 21 }}></span>
        <span style={{ "--i": 15 }}></span>
        <span style={{ "--i": 13 }}></span>
        <span style={{ "--i": 26 }}></span>
        <span style={{ "--i": 17 }}></span>
        <span style={{ "--i": 13 }}></span>
        <span style={{ "--i": 28 }}></span>
      </div>
      <Box
        sx={{
          alignItems: "center",
          padding: "40px 20px",
          display: "flex",
          flexDirection: "column",
          zIndex: 2,
        }}
      >
        <Typography
          variant="h4"
          fontWeight="bold"
          color="#fff"
          textAlign="center"
          mb={4}
        >
          Our Story
        </Typography>

        <Paper
          elevation={6}
          sx={{
            width: { xs: "100%", md: "80%", lg: "70%" },
            padding: "30px",
            backgroundColor: "#fff",
            borderRadius: "16px",
            boxShadow: "0px 10px 30px rgba(0, 0, 0, 0.1)",
            zIndex: 2,
          }}
        >
          <Typography
            variant="body1"
            color="textPrimary"
            fontSize="1.2rem"
            lineHeight="1.8"
            textAlign="justify"
          >
            <i>
              <span style={{ fontWeight: "bold", color: "#333" }}>
                Brain4Brains
              </span>{" "}
              was founded with a simple yet powerful mission: to make
              neuroscience education accessible and engaging for teens and
              children, regardless of their socioeconomic background. As a team
              that is deeply passionate about neuroscience, we noticed a
              gap—scientific terminology and complex concepts often make the
              subject feel out of reach for many.
              <br />
              <br />
              <span style={{ fontWeight: "bold", color: "#333" }}>
                Brain4Brains
              </span>{" "}
              aims to bridge that gap by breaking down dense neuroscience topics
              into easy-to-understand lessons that spark curiosity and a love
              for learning. With brain disorders becoming a leading global
              health concern, nearly 1 in 6 people worldwide suffer from
              neurological conditions ranging from migraines to Alzheimer's
              disease. As the need for young minds to enter the field grows, we
              provide accessible education and resources to inspire the next
              generation of neuroscientists and healthcare professionals.
              <br />
              <br />
              Through workshops, interactive lessons, and engaging content,{" "}
              <span style={{ fontWeight: "bold", color: "#333" }}>
                Brain4Brains
              </span>{" "}
              strives to make neuroscience fun, relatable, and accessible to
              all. Together, we can unlock the potential of the mind for the
              benefit of all.
            </i>
          </Typography>
        </Paper>
      </Box>
    </div>
  );
}

export default Animation;
