import React, { useEffect, useState } from "react";
import { Box, Typography } from "@mui/material";

const Counter = ({ target, label, duration }) => {
  const [count, setCount] = useState(0);

  useEffect(() => {
    const increment = Math.ceil(target / (duration / 100)); // Increment per interval
    const timer = setInterval(() => {
      setCount((prevCount) => {
        if (prevCount < target) {
          return Math.min(prevCount + increment, target); // Prevent exceeding the target
        }
        clearInterval(timer); // Clear interval when reaching the target
        return prevCount;
      });
    }, 100); // Update every 100ms

    return () => clearInterval(timer); // Cleanup on unmount
  }, [target, duration]);

  return (
    <Box textAlign="center" sx={{ margin: "0 20px" }}>
      <Typography variant="h3" sx={{ color: "#333", fontWeight: "bold" }}>
        {count}+
      </Typography>
      <Typography variant="body1" sx={{ color: "#333", fontWeight: "bold" }}>
        {label}
      </Typography>
    </Box>
  );
};
export default Counter;
