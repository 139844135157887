import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    isAddArticleOpen : false
}

const addArticleSlice = createSlice({
    name : 'addArticle',
    initialState,
    reducers:{
    setAddArticleOpen : (state,action) => {
        state.isAddedOpen = action.payload
    }
}
})
export const {setAddArticleOpen} = addArticleSlice.actions;
export default addArticleSlice.reducer;