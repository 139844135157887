import React from "react";
import { Box, Typography, Button } from "@mui/material";
import { useNavigate } from "react-router-dom";

const NotFoundPage = () => {
  const navigate = useNavigate();

  const handleGoHome = () => {
    navigate("/");
  };

  return (
    <Box
      sx={{
        height: "100vh",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
        backgroundColor: "#f5f5f5",
      }}
    >
      <Typography variant="h1" sx={{ color: "black", marginBottom: "20px" }}>
        404
      </Typography>
      <Typography variant="h5" sx={{ color: "gray", marginBottom: "20px" }}>
        Lost in the neural web—let's get back on track!
      </Typography>
    </Box>
  );
};

export default NotFoundPage;
