import React, { useState } from "react";
import { toast, ToastContainer } from "material-react-toastify";
import "material-react-toastify/dist/ReactToastify.css";
import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import Toolbar from "@mui/material/Toolbar";
import Menu from "@mui/material/Menu";
import MenuIcon from "@mui/icons-material/Menu";
import Container from "@mui/material/Container";
import Button from "@mui/material/Button";
import MenuItem from "@mui/material/MenuItem";
import logo from "../logo.png";
import { useDispatch } from "react-redux";
import { toggleDrawer } from "../slice/cartSlice";
import { pages } from "../constants";
import NotificationDropdown from "./Notification";
import { ShoppingCart } from "@mui/icons-material";
import { IconButton } from "@mui/material";

export default function Header({ route }) {
  const dispatch = useDispatch();
  const handleCartClick = () => {
    dispatch(toggleDrawer(true));
  };

  const [anchorElNav, setAnchorElNav] = useState(null);
  const [dropdownAnchor, setDropdownAnchor] = useState(null);

  const handleOpenNavMenu = (event) => {
    setAnchorElNav(event.currentTarget);
  };

  const handleCloseNavMenu = () => {
    setAnchorElNav(null);
  };

  const handleAboutClick = (event) => {
    setDropdownAnchor(event.currentTarget);
  };

  const handleDropdownClose = () => {
    setDropdownAnchor(null);
  };

  const isActive = (link) => {
    const currentPath = window.location.pathname;

    if (
      link === "About Us" &&
      (currentPath === "/our-story" || currentPath === "/leadership-panel")
    ) {
      return true;
    }

    return currentPath === link;
  };

  return (
    <AppBar
      position="static"
      style={{
        backgroundColor: "transparent",
        alignItems: "right",
        top: 0,
        position: "sticky",
        zIndex: 9999,
      }}
    >
      <ToastContainer
        position="top-right"
        autoClose={3000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />
      <Container maxWidth="xl" sx={{ backgroundColor: "#FFF" }}>
        <Toolbar disableGutters>
          <Button
            style={{ width: "50px", marginRight: 1 }}
            sx={{ display: { xs: "none", md: "flex" } }}
          >
            <a href="/">
              <img src={logo} alt="logo" style={{ width: "100%" }} />
            </a>
          </Button>

          <Box sx={{ flexGrow: 1, display: { xs: "flex", md: "none" } }}>
            <Button
              size="large"
              aria-label="menu"
              onClick={handleOpenNavMenu}
              color="primary"
            >
              <MenuIcon sx={{ color: "black" }} />
            </Button>
            <Menu
              id="menu-appbar"
              anchorEl={anchorElNav}
              anchorOrigin={{
                vertical: "bottom",
                horizontal: "right",
              }}
              keepMounted
              transformOrigin={{
                vertical: "top",
                horizontal: "right",
              }}
              open={Boolean(anchorElNav)}
              onClose={handleCloseNavMenu}
            >
              {pages.map((page) => (
                <MenuItem
                  key={page.name}
                  onClick={handleCloseNavMenu}
                  sx={{
                    color: "#333",

                    borderRadius: "0 !important",
                  }}
                >
                  {page.name === "About Us" ? (
                    <Button onClick={handleAboutClick} sx={{ color: "black" }}>
                      {page.name}
                    </Button>
                  ) : (
                    <Button
                      onClick={(e) => {
                        e.preventDefault();
                        if (page.link === "/courses") {
                          toast.info("Courses is under development");
                        } else {
                          window.location.href = page.link;
                        }
                      }}
                      sx={{ color: "black" }}
                    >
                      {page.name}
                    </Button>
                  )}
                </MenuItem>
              ))}
            </Menu>
          </Box>

          <Box
            sx={{
              flexGrow: 1,
              justifyContent: "right",
              gap: "30px",
              display: { xs: "none", md: "flex" },
            }}
          >
            {pages.map((page) => (
              <Button
                key={page.name}
                sx={{
                  fontSize: "15px",
                  color: "#333",
                  display: "block",
                  fontFamily: "Urbanist",
                  borderBottom:
                    isActive(page.link) ||
                    ((window.location.pathname === "/our-story" ||
                      window.location.pathname === "/leadership-panel") &&
                      page.name === "About Us")
                      ? "2px solid #f57c00"
                      : "none",
                  paddingBottom: "8px",
                  borderRadius: "0 !important",
                }}
                onClick={(e) => {
                  e.preventDefault();
                  if (page.name === "Courses") {
                    toast.info("Courses is under development");
                  } else if (page.name === "About Us") {
                    handleAboutClick(e);
                  } else {
                    window.location.href = page.link;
                  }
                }}
              >
                {page.name}
              </Button>
            ))}
          </Box>

          <Menu
            anchorEl={dropdownAnchor}
            open={Boolean(dropdownAnchor)}
            onClose={handleDropdownClose}
            anchorOrigin={{
              vertical: "bottom",
              horizontal: "left",
            }}
            transformOrigin={{
              vertical: "top",
              horizontal: "left",
            }}
          >
            <MenuItem onClick={() => (window.location.href = "/our-story")}>
              Our Story
            </MenuItem>
            <MenuItem
              onClick={() => (window.location.href = "/leadership-panel")}
            >
              Leadership Panel
            </MenuItem>
          </Menu>
          {window.location.pathname === "/courses" && (
            <IconButton onClick={handleCartClick}>
              <ShoppingCart
                sx={{ color: "black", paddingLeft: "20px", cursor: "pointer" }}
              />
            </IconButton>
          )}
          <NotificationDropdown />
        </Toolbar>
      </Container>
    </AppBar>
  );
}
