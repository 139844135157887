import React from "react";
import { Box, Typography, Button, Card, CardMedia } from "@mui/material";
import { styled } from "@mui/system";
import landingimage from "../../assets/landing1.jpg";
import { Link } from "react-router-dom";
const RootContainer = styled(Box)(({ theme }) => ({
  display: "flex",
  justifyContent: "space-between",
  alignItems: "center",
  padding: "40px",
  backgroundColor: "hsl(0, 0%, 90%)",
  margin: "0 auto",
  [theme.breakpoints.down("sm")]: {
    flexDirection: "column", // Stack content vertically on small screens
    padding: "20px", // Reduce padding on smaller screens
  },
}));

const TextSection = styled(Box)(({ theme }) => ({
  flex: "1",
  maxWidth: "50%",
  [theme.breakpoints.down("sm")]: {
    maxWidth: "100%", // Take full width on small screens
    textAlign: "center", // Center align text on small screens
  },
}));

const Title = styled(Typography)(({ theme }) => ({
  fontSize: "2.5rem",
  fontWeight: "bold",
  color: "#333",
  marginBottom: "16px",
  [theme.breakpoints.down("sm")]: {
    fontSize: "2rem", // Adjust font size for small screens
  },
}));

const Description = styled(Typography)(({ theme }) => ({
  fontSize: "1.2rem",
  color: "#555",
  marginBottom: "24px",
  [theme.breakpoints.down("sm")]: {
    fontSize: "1rem", // Adjust font size for small screens
  },
}));

const ActionButton = styled(Button)(({ theme }) => ({
  fontSize: "1rem",
  border: "2px solid #333",
  color: "#333",
  padding: "12px 24px",
  textTransform: "none",
  [theme.breakpoints.down("sm")]: {
    padding: "10px 20px", // Adjust padding on small screens
  },
}));

const ImageContainer = styled(Box)(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  position: "relative",
  [theme.breakpoints.down("sm")]: {
    marginTop: "20px", // Add margin on top for small screens
    justifyContent: "center", // Center image on small screens
  },
}));

const ReportImage = styled(Card)(({ theme }) => ({
  transform: "rotate(-5deg)",
  boxShadow: "0 4px 20px rgba(0, 0, 0, 0.1)",
  width: "280px",
  height: "auto",
  marginRight: "16px",
  [theme.breakpoints.down("sm")]: {
    width: "200px", // Adjust image width for small screens
    marginRight: "0", // Remove right margin on small screens
  },
}));

export default function FeaturedReportSection() {
  return (
    <RootContainer>
      {/* Text Section */}
      <TextSection>
        <Title>The Future of Neuroscience</Title>
        <Description>
          Explore how cutting-edge research is unlocking the mysteries of the
          brain. Dive into the latest discoveries, innovative tools, and
          transformative insights shaping our understanding of the human mind.
        </Description>
        <ActionButton
          variant="contained"
          color="warning"
          sx={{ borderRadius: "25px", border: "none" }}
          component={Link}
          to="/our-story"
        >
          Explore
        </ActionButton>
      </TextSection>

      {/* Image Section */}
      <ImageContainer>
        <ReportImage>
          <CardMedia
            component="img"
            alt="Report Cover"
            image={landingimage} // Replace with actual image URL
          />
        </ReportImage>
      </ImageContainer>
    </RootContainer>
  );
}
